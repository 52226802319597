import React, { useContext, useState } from 'react';

import useNewslistBanner from 'data/queries/useNewslistBanner';
import { RoutesContext } from 'context/RoutesContext';
import CustomLink from 'components/utils/CustomLink';
import EmbedVideoPlayer from 'components/utils/EmbedVideoPlayer';
import Modal from 'components/utils/Modal';
import returnDate from 'utils/returnDate';
import { LangContext } from 'context/LangContext';
import * as style from 'styles/components/news/newsBanner.module.scss';

import Image from '../utils/Image';
import FormattedMessage, { getFormattedMessage } from '../utils/FormattedMessage';

const NewsBanner = () => {
    const { lang } = useContext(LangContext);
    const { routes } = useContext(RoutesContext) || { routes: [] };
    const newsBanner = useNewslistBanner().find((news) => news.language.hreflang === lang.hreflang);
    const [videoPlaying, setVideoPlaying] = useState(false);

    let newsListRoute = '';
    if (routes && routes.length !== 0) {
        newsListRoute = routes.find(
            (route) => route.node.name === 'newsList' && route.node.lang === lang.hreflang,
        ).node.path;
    }

    return (
        <div className={style.newsListBanner}>
            <div className={style.newsListBanner__link}>
                {newsBanner.video &&
                    <div onClick={() => setVideoPlaying(true)} onKeyDown={() => setVideoPlaying(true)} role="presentation" />
                }
                {!newsBanner.video &&
                    <CustomLink to={`/${newsListRoute}/${newsBanner.slug.current}`} />
                }
            </div>
            <div className={style.newsListBanner__main}>
                {newsBanner.image?.defaultImage && (
                    <Image image={newsBanner.image.defaultImage} width="2000" />
                )}
                <div className={style.newsListBanner__content}>
                    <p className="text-xs text-medium">
                        {returnDate({ item: newsBanner, lang: lang.hreflang })}
                    </p>
                    <h1
                        className={`title-xl title-sm-mobile text-bold ${style.newsListBanner__title}`}
                    >
                        {newsBanner.title}
                    </h1>
                    {newsBanner.video &&
                        <button type="button" className="cta-white-sec" onClick={() => setVideoPlaying(true)} aria-label={getFormattedMessage('global.watchClip', lang)}>
                            <FormattedMessage id="global.watchClip" />
                        </button>
                    }
                    {!newsBanner.video &&
                    <CustomLink
                        to={`/${newsListRoute}/${newsBanner.slug.current}`}
                        className="cta-white-sec"
                    >
                        <FormattedMessage id="global.readNews" />
                    </CustomLink>
                    }
                </div>
            </div>

            {videoPlaying && (
                <Modal
                    className={style.newsListBanner__videoModal}
                    show={videoPlaying}
                    onClose={() => setVideoPlaying(false)}
                >
                <EmbedVideoPlayer
                    url={`${newsBanner.video.teaserVideo.url}?auto_play=true`}
                    title={newsBanner.title}
                />
                </Modal>
            )}
        </div>
    );
};

export default NewsBanner;
